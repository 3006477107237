import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import CiFormMakeValidationSchema from './CiFormMakeValidationSchema'
import InvoiceSummaryMakeValidationSchema from './invoiceSummary/InvoiceSummaryMakeValidationSchema'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are ' + ValidationRegex.errMsgAlphaNumeric
  const regexDigitWithDecimal = ValidationRegex.regex7DigitWith4Decimal
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 7 digits and 4 decimal for the Total Shipment Quantity'
  const regexDigitWithDecimalGrossWeight = ValidationRegex.regex7DigitWith1Decimal
  const errMsgDigitWithDecimalGrossWeight =
    'Please enter only numeric values with up to 7 digits and 1 decimal'
  const regexAlphaNumericwithChar = '^[a-zA-Z0-9\\-\\ \\.\\,]*$'
  let errMsgAlphaNumericwithChar = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_COMA_DOT_DASH
  )
  // const errMsgAlphaNumericwithChar =
  //   'Please enter alpha numeric values or Special Characters (-., or space)'
  const regexAlphaNumericScac = '^[a-zA-Z0-9\\-]*$'
  let errMsgAlphaNumericScac = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_DASH
  )
  // const errMsgAlphaNumericScac =
  //   'Please enter only alpha numeric values or special characters (-) for SCAC code'

  return Yup.object({
    invoiceNumber: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumericwithChar, errMsgAlphaNumericwithChar)
      .max(35, 'Max length allowed is 35')
      .nullable(),
    scacCode: Yup.string()
      .matches(regexAlphaNumericScac, errMsgAlphaNumericScac)
      .max(4, 'Max length allowed is 4')
      .nullable(),
    invoiceDate: Yup.date()
      .validFormat()
      .typeError(dateTypeErrorMessage)
      .required(requiredMessage),
    carrierName: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, 'Max length allowed is 30')
      .nullable(),
    purchaseOrderNumber: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumericwithChar)
      .max(22, 'Max length allowed is 22')
      .nullable(),
    shipmentControlNumber: Yup.string()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(12, 'Max length allowed is 12')
      .nullable(),
    currency: Yup.string().required(requiredMessage).nullable(),
    foreignPortOfExit: Yup.string().nullable(),
    totalShipmentQuantity: Yup.string()
      .required(requiredMessage)
      .nullable()
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    portofEntry: Yup.string().required(requiredMessage).nullable(),
    totalShipmentQuantityUOM: Yup.string()
      .required(requiredMessage)
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(2, 'Max length allowed is 02'),
    exportCarrier: Yup.string()
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, 'Max length allowed is 30')
      .nullable(),
    transportationMode: Yup.string().required(requiredMessage).nullable(),
    totalItemsGrossWeight: Yup.string()
      .nullable()
      .matches(
        regexDigitWithDecimalGrossWeight,
        errMsgDigitWithDecimalGrossWeight
      ),
    totalItemsGrossWeightUom: Yup.string().nullable(),
    status: Yup.string().nullable(),
    entryNumber: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, 'Max length allowed is 30'),
    totalItemsNetWeight: Yup.string()
      .nullable()
      .matches(
        regexDigitWithDecimalGrossWeight,
        errMsgDigitWithDecimalGrossWeight
      )
      .max(9, 'Max value allowed is 9'),
    totalItemsNetWeightUom: Yup.string().nullable(),
    description: Yup.string()
    .required(requiredMessage)
    .matches(regexAlphaNumeric, errMsgAlphaNumeric)
    .max(80, 'Max length allowed is 80')
    .nullable(),
    tradeParty: Yup.array(),
    invoiceDetails: Yup.array(),
    nbciusinvoiceDetailsReference: Yup.array(),
    nbciusinvoiceDetailsService: Yup.array(),
    nbciusinvoiceDetailsDOTForm: Yup.array(),
    nbciusinvoiceDetailsFDAForm: Yup.array(),
    nbciusinvoiceDetailsFCCForm: Yup.array(),
    nbciusinvoiceDetailsEPA35201Form: Yup.array(),
    nbciusinvoiceDetailsEPA352021Form: Yup.array(),
	invoiceSummary: Yup.array().of(InvoiceSummaryMakeValidationSchema(translate)),
    nbciUSInvoiceShipmentSummary: Yup.array(),
    nbciusinvoiceSummaryReference: Yup.array(),
    laceyAct: Yup.array(),
    nbciusinvoiceLaceyActDetails: Yup.array(),
    packingList: Yup.array(),
    tscaform: Yup.array(),
    nbciusinvoiceTCSADetails: Yup.array(),
    bolForm: Yup.array(),
    fdabtaform: Yup.array(),
    usAPHISForm: Yup.array(),
    nbciusinvoiceUSAPHISAnimalFormDTO: Yup.array(),
    naftaForm: Yup.array(),
    ciForm: Yup.array().of(CiFormMakeValidationSchema(translate)),
    nbciusinvoiceCargoSummary: Yup.array()
  })
}

export default makeValidationSchema

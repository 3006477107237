import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import UsDeclarationsKeys from '../../../constants/locale/key/UsDeclarations'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const maxLengthErrorMessage = translate(
    Namespace.US_DECLARATIONS,
    UsDeclarationsKeys.Validations.MAX_LENGTH
  )
  const alphaNumericErrorMessage = translate(
    Namespace.US_DECLARATIONS,
    UsDeclarationsKeys.Validations.ALPHANUMERIC
  )
  let errMsgAlphaNumericSpecChar = translate(
    Namespace.US_DECLARATIONS,
    UsDeclarationsKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  const regexAlphaNumeric = '^[a-zA-Z0-9]*$'

  return Yup.object({
    declarationId: Yup.string()
      .max(10, maxLengthErrorMessage + ' 10')
      .required(requiredMessage)
      .matches(regexAlphaNumeric, alphaNumericErrorMessage)
      .nullable(),
    declarationDescription: Yup.string().required(requiredMessage).matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
  })
}

export default makeValidationSchema
